import React, { useState, useEffect, useContext } from 'react';
import logo from '../../assets/logo-home.png';
import './styles.css';
import '../../App.css';
import Lottie from 'react-lottie';
import iconLoading from '../../assets/icons/loading.json';

function RegisterSuccess({ history }) {
  const [category, setCategory] = useState("");
  const [buttonVisible, setButtonVisible] = useState(false);

  //Ao abrir a tela
  useEffect(() => {
    setTimeout(() => {
      setButtonVisible(true);
      history.push('/creator/dashboard');
    }, 5000);
  }, []);

  return (
  <>
    <div className="container v-center">
      <div id="cadastro" className="col-12 col-md-8 offset-md-2">
        <div className="text-center">
          <br/> <br/> <br/> <br/> <br/> <br/>
          <img id="logo" src={logo}/>
          <br/>
          <h3>Cadastro Efetuado!</h3>
          <br/>
          <p>Seu cadastro foi realizado com sucesso! Estamos preparando o seu painel administrativo, você já será redirecionado...</p>
        </div> 
        <div className="">
          <div className="col-12">
            <div className="text-center">
              <Lottie options={{
                loop: true,
                autoplay: true, 
                animationData: iconLoading,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }}}
                height={120}
                width={120}
                style={{}}
              />
            </div>
          </div>
          <br /> <br />
          { buttonVisible ?
            <div className="row">
              <div className="col-12 text-center">
                <a href="./creator/dashboard" target="_Blank" className="btn btn-default-outline">
                  <i className="fa fa-external-link-alt fa-lg"> </i>
                  <span style={{marginLeft: 5}}> Acessar o Painel </span>
                </a>
              </div>
            </div>
          : <></> }
        </div>
      </div>
    </div>
</>);
}

export default RegisterSuccess;
