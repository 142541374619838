import React, { useState, useEffect, useContext } from 'react';
import logo from '../../assets/logo-home.png';
import './styles.css';
import '../../App.css';
import Api from '../../services/Api'
import Loading from '../../components/Loading';
import Functions from '../../components/Functions';
import UserContext from '../../contexts/UserContext';

function Register({ history }) {
  const [loading, setLoading] = useState(true);
  const [subTitle, setSubTitle] = useState('Junte-se à nós e receba os comentários pagos da sua audiência em um só lugar!');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [channel, setChannel] = useState('');
  const [pass, setPass] = useState('');
  const [passConfirm, setPassConfirm] = useState('');
  const [msgErro, setMsgErro] = useState("");
  const {user, setUser} = useContext(UserContext);

  //Ao abrir a tela
  useEffect(() => {
    setLoading(false);
  }, []);
  
  function validateEmail(mail) {
    if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
      return (true)
    } else{
      return (false)
    }
  }
  
  async function handleSubmit(event) {
    event.preventDefault();
    setMsgErro("");
    if(name !== '' && email !== '' && pass !== '' && passConfirm !== ''){
      if(validateEmail(email)){
        if(pass.length >= 6){
          if(pass === passConfirm){
            setLoading(true);
            var data = {
              "name": name,
              "email": email,
              //"channel": channel,
              "password": pass
            };
            var route = "/store-user";
            var response = await Api.post(route, data);
            if(response){
              console.log(response);
              sessionStorage.setItem('user', response._id);
              setUser([response]);
              history.push('/creator/register-success');
              /*toast.success("Seu cadastro foi realizado com sucesso!\nAcesse o app e faça o login!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });*/
            } else {
              setMsgErro("Falha ao realizar o cadastro! Tente novamente em breve!");
            }
            setTimeout(() => {
              setLoading(false);
            }, 500);
          } else {
            setMsgErro("As senhas não conferem!");
          }
        } else {
          setMsgErro("A senha deve conter no mínimo 6 caracteres!");
        }
      } else {
        setMsgErro("Por favor, informe um e-mail válido!");
      }
    } else {
      setMsgErro("Por favor, preencha todos os campos!");
    }
  }

  return (
  <>
    <Loading visible={loading}/>
    <div className="container">
      <div id="cadastro" className="col-12 col-md-8 offset-md-2">
        <div className="text-center">
          <br/> <br/> <br/>
          <img id="logo" src={logo}/>
          <br/>
          <h3>Crie sua Conta!</h3>
          <p>{subTitle}</p>
        </div> 
        <br/>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="labelInput">Nome: *</label>
            <input 
              required
              type="text" 
              className="form-control" 
              placeholder="Seu nome"
              value={name}
              onChange={event => setName(event.target.value)}/>
          </div>
          <div className="form-group">
            <label className="labelInput">E-mail: *</label>
            <input 
              required
              type="email" 
              className="form-control" 
              placeholder="Seu e-mail"
              value={email}
              onChange={event => setEmail(event.target.value)}/>
          </div>      
          {/*<div className="form-group">
            <label className="labelInput">Nome do seu Canal: *</label>
            <input 
              required
              type="text" 
              className="form-control" 
              placeholder="Informe seu canal ou seu nome de streaming"
              value={channel}
              onChange={event => setChannel(event.target.value)}/>
          </div>*/}   
          <div className="form-group">
            <label className="labelInput">Senha: *</label>
            <input 
              required
              type="password"  
              className="form-control" 
              name="senha" id="senha" 
              placeholder="Sua senha"
              value={pass}                
              onChange={event => setPass(event.target.value)}/>
          </div>
          <div className="form-group">
            <label className="labelInput">Confirmar Senha: *</label>
            <input 
              required
              type="password"  
              className="form-control" 
              name="senha" id="senha" 
              placeholder="Sua senha"
              value={passConfirm}                
              onChange={event => setPassConfirm(event.target.value)}/>
          </div>
          <p className={`col-12 alert alert-danger ${msgErro === "" ? 'hidden' : ''}`} onClick={() => setMsgErro("")}>{msgErro}</p>
          <br/>
          <button type="submit" className="btn btn-default col-8 offset-2">Criar Conta</button>
          <br/><br/>
        </form>
      </div>
    </div>
</>);
}

export default Register;
