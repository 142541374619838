import React, { useState, useMemo, useEffect } from 'react';
import InputMask from "react-input-mask";
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';

const ListItemLive = ({ id, liveID, name, description, thumbnail, link, business, active, onDelete, loadItem, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateName, setStateName] = useState(name);
  const [stateDescription, setStateDescription] = useState(description);
  const [stateThumbnail, setStateThumbnail] = useState(thumbnail);
  const [stateLink, setStateLink] = useState(link);
  const [stateLinkPage, setStateLinkPage] = useState("https://paychat.com.br/live/"+liveID);
  const [stateActive, setStateActive] = useState(active);

  // useMemo: executa quando determinado estado for alterado, neste caso o estado 'stateThumbnail'
  const preview = useMemo(() => {
    if(thumbnail !== "" && thumbnail === stateThumbnail){
      return thumbnail;
    } else {
      return stateThumbnail ? URL.createObjectURL(stateThumbnail) : null;
    }
  }, [stateThumbnail]);
  
  function onChangeThumbnailFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      //let file_name = e.target.files[0].name;
      //let file_type = e.target.files[0].type;
      //console.log('executou o file change: ' +  file_size);
      //Se maior que um 1mb(1048576)
      if(parseInt(file_size) >= 1048000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 1MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }else{
        setStateThumbnail(e.target.files[0])
      }
    }
  };

  async function handleSubmit(e){
    e.preventDefault();
    if(stateName !== "" && stateLink !== ""){
      setLoading(true);
      var fileThumbnail = stateThumbnail;
      //Cria um estrutura Multiform Data;
      const data = new FormData();
      data.append('id', id);
      data.append('liveID', liveID);
      data.append('business', business);
      data.append('name', stateName.trim());
      data.append('link', stateLink);
      data.append('description', stateDescription.trim());
      if(typeof fileThumbnail === "object" && thumbnail !== fileThumbnail.name){
        data.append('thumbnail', thumbnail);
        data.append('file', fileThumbnail);
      }
      data.append('active', stateActive);
      try {
        const response = await Api.post("/store-live", data);
        console.log(response);
        if(response.hasOwnProperty('_id') || response?.ok){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setToggleItem([]);
          const reloadItem = loadItem;
          reloadItem();
          setLoading(false);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  /*function copyToClipboard(e) {
    var textField = document.createElement('textarea')
    textField.innerText = stateLinkPage;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    toast.success("Link copiado com sucesso!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };*/

  function copyToClipboard(text) {
    let textarea, result;
    try {
      textarea = document.createElement('textarea');
      textarea.setAttribute('readonly', true);
      textarea.setAttribute('contenteditable', true);
      textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      const range = document.createRange();
      range.selectNodeContents(textarea);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand('copy');
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }
    // manual copy fallback using prompt
    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
      result = prompt(`Press ${copyHotkey}`, text); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }
    toast.success("Link copiado com sucesso!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    return true;
  }
  
  return (
      <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"} style={active || stateActive ? {} : {opacity: 0.4}}>
        <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
          <div className="col-10">
            <span>{stateName}</span>
          </div>
          <div className="col-2 text-right">
            <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === id ?
          <div className="row">
            <div className="col-12">
              <hr/>
              <form onSubmit={handleSubmit}>
                <div className="row v-center">
                  <div className="col-12">
                    <label className="switch itemDashboardListSwitch" style={{marginTop: -60, marginRight: 45}}>
                      <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                      <div className="slider"></div>
                    </label>
                    <div className="row">
                      {/*<div className="col-12">
                        <div className="form-group">
                          <label className="labelImage">Imagem:</label>
                          <label id="thumbnail" style={{ backgroundImage: `url(${preview})` }} className={stateThumbnail ? 'has-thumbnail' : ''}>
                            <input type="file" accept=".jpg, .jpeg, .png" onChange={onChangeThumbnailFile} />
                            <img src={camera} alt="Select img" />
                          </label>
                        </div>
                      </div>*/}
                      <div className="col-12">
                        <div className="form-group">
                          <label className="labelInput">Link da Página de Comentários: *</label>
                          <input 
                            required
                            disabled
                            type="text" 
                            className="form-control" 
                            placeholder="Link gerado para a página dos comentários"
                            value={stateLinkPage}
                            onChange={event => setStateLinkPage(event.target.value)}
                          />
                        </div>
                        <span type="button" className="btn-input" style={{}} onClick={()=> window.open("https://ilinks.com.br/gerador-qr-code.php?url="+stateLinkPage, "_Blank")} title="Gerar Qr Code"> <i className="fa fa-qrcode"></i> </span>
                        <span type="button" className="btn-input" style={{marginRight: 75}} onClick={() => copyToClipboard(stateLinkPage)} title="Copiar Link"> <i className="fa fa-copy"></i> </span>
                        <div className="form-group">
                          <label className="labelInput">Título da Transmissão: *</label>
                          <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Título da transmissão"
                            autoCapitalize="sentences"
                            value={stateName}
                            onChange={event => setStateName(event.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="labelInput">Link da Transmissão: *</label>
                          <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Cole aqui o link da transmissão"
                            value={stateLink}
                            onChange={event => setStateLink(event.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="labelInput">Observações: </label>
                          <textarea 
                            className="form-control" 
                            placeholder="Caso deseja informar algo a sua audiência..."
                            value={stateDescription} 
                            onChange={event => setStateDescription(event.target.value)} 
                            />
                        </div>
                        {/*<div className="form-group">
                          <label>
                            <input 
                              type="checkbox"
                              style={{marginLeft: 15, marginTop: 5, marginRight: 8, transform: 'scale(1.5)', cursor: "pointer", filter: "hue-rotate(41deg)"}}
                              onChange={ () => setStatePreBooking(!statePreBooking) }
                              value={statePreBooking}
                              checked={ statePreBooking }
                            />
                            Requer pré reserva?
                          </label>
                        </div>*/}
                      </div>     
                    </div>     
                  </div>     
                      
                  <div className="col-12 text-right">
                    <button type="button" className="btn btn-delete" onClick={onDelete}> Excluir </button>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <button type="submit" className="btn btn-default"> Salvar </button>
                  </div>
                </div>
              </form>
                
            </div>
          </div>
          : '' }
      </li>
  );
};

export default ListItemLive;
