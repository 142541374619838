//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useRef, useMemo} from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/logo-home.png';
//import homeAnimation from '../../assets/home-animation.gif';
import bgDefault from '../../assets/banner.jpg';
import logoWhite from '../../assets/logo-white.png';
import iconUser from '../../assets/icons/icon-profile.png';
import { ToastContainer, toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import Api from '../../services/Api'
import Functions from '../../components/Functions';
import camera from '../../assets/icons/camera.svg';
import Loading from '../../components/Loading';
import Modal from 'react-modal';
import './styles.css';
import '../../App.css';

Modal.setAppElement(document.getElementById('root'));

export default function Dashboard({ history }) {
  const [loading, setLoading] = useState(true);
  const [live, setLive] = useState([]);
  const [messages, setMessages] = useState([ // Editando, Aguardando Pagameto, Pago
    //{name: "Cleiton Schú", value:5.00, status:"Pago", message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus sed erat sit amet tincidunt. Sed porta nec sem quis egestas. Cras sed luctus velit."},
    //{name: "Bobe JR", value:10.00, status:"Pago", message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus sed erat sit amet tincidunt."},
    //{name: "Cleiton Schú", value:20.00, status:"Pago", message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus sed erat sit amet tincidunt. Sed porta nec sem quis egestas. Cras sed luctus velit."},
    //{name: "Bobe JR", value:30.00, status:"Pago", message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus sed erat sit amet tincidunt."},
    //{name: "Cleiton Schú", value:50.00, status:"Pago", message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus sed erat sit amet tincidunt. Sed porta nec sem quis egestas. Cras sed luctus velit."},
    //{name: "Bobe JR", value:80.00, status:"Pago", message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus sed erat sit amet tincidunt."},
    //{name: "Cleiton Schú", value:100.00, status:"Pago", message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus sed erat sit amet tincidunt. Sed porta nec sem quis egestas. Cras sed luctus velit."},
    //{name: "Bobe JR", value:200.00, status:"Pago", message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus sed erat sit amet tincidunt."},
    //{name: "Cleiton Schú", value:35.00, status:"Pago", message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus sed erat sit amet tincidunt. Sed porta nec sem quis egestas. Cras sed luctus velit."},
    //{name: "Bobe JR", value:80.00, status:"Pago", message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus sed erat sit amet tincidunt."},
  ]);
  const [name, setName] = useState("");
  const [CPF, setCPF] = useState("");
  const [newMessage, setNewMessage] = useState(
    {name: "...", value: 1000, status: "Editando", codePix: "", QRCodePix: "", message: ""}
  );
  const [myMessages, setMyMessages] = useState([]);
  const [messageSelected, setMessageSelected] = useState({});
  //const [codePix, setCodePix] = useState("");
  const location = useLocation();
  const refEditMessage = useRef(null);
  const [modalEmogi, setModalEmogi] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const [modalPix, setModalPix] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);

  const customStylesModal = {
    overlay: {
      background: 'rgba(0,0,0,0.5)',
      zIndex: 999
    }, 
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '95%',
      maxWidth: '550px',
      border: 0,
      padding: '15px',
      borderRadius: '15px',
      boxShadow: '0px 0px 5px -2px #bbbbbb'
    }
  };    

  useEffect(() => {
    var pathname = history.location.pathname.split("/");
    var liveID = "", interval = 0;
    if(pathname.length > 1 && pathname[2] !== ""){
      liveID = pathname[2];
    }
    //console.log(liveID);
    if(liveID && liveID !== ""){
      loadLive(liveID);
      interval = setInterval(() => {
        loadMessages(liveID);
      }, 20000);
      const savedMessages = localStorage.getItem(liveID);
      const savedName = localStorage.getItem("name");
      const savedCPF = localStorage.getItem("CPF");
      //console.log(savedMessages);
      if(savedMessages){
        setMyMessages(JSON.parse(savedMessages));
      }
      if(savedName){
        setName(savedName);
        setNewMessage({name: savedName !== "" ? savedName : "...", value: 1000, status: "Editando", codePix: "", QRCodePix: "", image: "", video: "", message: ""});
      }
      if(savedCPF){
        setCPF(savedCPF);
      }
    } else {
      history.push('/');
    }
    return () => clearInterval(interval);
  }, [location]);


  async function loadLive(idLive) {
    try {
      const response = await Api.get("/get-live/"+idLive.toLowerCase());
      console.log(response);
      if(response.length > 0){
        setLive(response);
        loadMessages(idLive)
      } else {
        toast.error("Página não encontrada! Você será redirecionado...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setTimeout(() => {
          history.push('/');
        }, 3500);
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function loadMessages(idLive) {
    try {
      console.log("Buscando comentários --> " + new Date());
      const response = await Api.get("/get-live-comments/"+idLive.toLowerCase());
      const savedMessages = localStorage.getItem(idLive);
      //console.log(savedMessages);
      var arrMyMessages = savedMessages ? JSON.parse(savedMessages) : [], responseFiltered = [];
      await response.forEach(async (item) => {
        await arrMyMessages.every((itemSaved, index) => {
          if(item._id === itemSaved._id){
            item.myMessage = true;
            arrMyMessages.splice(index, 1);
            return false;
          } else {
            item.myMessage = false;
            return true;
          }
        });
        responseFiltered.push(item);
      });
      //console.log(arrMyMessages);
      console.log(responseFiltered);
      setMessages(responseFiltered);
      setMyMessages(arrMyMessages);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  // useMemo: executa quando determinado estado for alterado, neste caso o estado 'newMessage.image'
  const preview = useMemo(() => {
    return newMessage.image ? URL.createObjectURL(newMessage.image) : null;
  }, [newMessage.image]);

  function onChangeImageFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      //let file_name = e.target.files[0].name;
      //let file_type = e.target.files[0].type;
      //console.log('executou o file change: ' +  file_size);
      //Se maior que um 1mb (1048576)
      if(parseInt(file_size) >= 5240000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 5MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setNewMessage({name: newMessage.name, value: newMessage.value, status:newMessage.status, codePix:newMessage.codePix, QRCodePix: newMessage.QRCodePix, image: e.target.files[0], video: "", message: newMessage.message});
      }
    }
  };

  const previewVideo = useMemo(() => {
    return newMessage.video ? URL.createObjectURL(newMessage.video) : null;
  }, [newMessage.video]);

  function onChangeVideoFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      //let file_name = e.target.files[0].name;
      //let file_type = e.target.files[0].type;
      //console.log('executou o file change: ' +  file_size);
      //Se maior que um 1mb (1048576)
      if(parseInt(file_size) >= 52400000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 50MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setNewMessage({name: newMessage.name, value: newMessage.value, status:newMessage.status, codePix:newMessage.codePix, QRCodePix: newMessage.QRCodePix, image: "", video: e.target.files[0], message: newMessage.message});
      }
    }
  };

  function openModalPix(e){
    e.preventDefault();
    console.log(newMessage.value);
    if((newMessage.message === "" || newMessage.message === " ") && newMessage.image === "" && newMessage.video === ""){
      toast.error("Por favor, insira uma mensagem!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if(!newMessage?.value || newMessage.value <= 0){
      toast.error("Por favor, insira um valor para o seu comentário!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      setModalPix(!modalPix);
    }
  }

  function openModalDetails(message){
    setMessageSelected(message);
    setModalDetails(!modalDetails);
  }

  async function generatePix(e){
    e.preventDefault();
    if(name !== "" && CPF !== "" && newMessage.value > 50){
      if(newMessage.message.trim() !== "" || newMessage.image !== "" || newMessage.video !== ""){
        setLoading(true);
        /*const data = {
          liveID: live[0].liveID,
          name: name.trim(),
          cpf: CPF,
          message: newMessage.message,
          value: parseFloat(parseFloat(newMessage.value/100).toFixed(2)),
        }*/
        const data = new FormData();
        data.append('liveID', live[0].liveID);
        data.append('business', live[0].business._id);
        data.append('name', name.trim());
        data.append('cpf', CPF);
        data.append('message', newMessage.message);
        data.append('value', parseFloat(parseFloat(newMessage.value/100).toFixed(2)));
        if(newMessage.image !== "" && typeof newMessage.image === "object"){
          console.log("heve a image.....");
          data.append('image', true);
          data.append('file', newMessage.image);
        }
        if(newMessage.video !== "" && typeof newMessage.video === "object"){
          console.log("heve a video.....")
          data.append('video', true);
          data.append('file', newMessage.video);
        }
        console.log(data);
        try {
          const response = await Api.post("/create-payment", data);
          console.log(response);
          //if(response && response?.response && response.response.hasOwnProperty("point_of_interaction")){
            //setCodePix(response.response.point_of_interaction.transaction_data.qr_code);
          if(response && response?.codePix){
            setMyMessages([...myMessages, response]);
            setNewMessage({name: name, value: 1000, status:"Editando", codePix: "", QRCodePix: "", image: "", video: "", message: ""});
            setModalPix(false);
            openModalDetails(response);
            localStorage.setItem("name", name);
            localStorage.setItem("CPF", CPF);
            const savedMessages = localStorage.getItem(live[0].liveID);
            if(savedMessages) {
              var arrMyMessagens = JSON.parse(savedMessages);
              arrMyMessagens.push(response);
              localStorage.setItem(live[0].liveID, JSON.stringify(arrMyMessagens));
            } else {
              localStorage.setItem(live[0].liveID, JSON.stringify([response]));
            }
            toast.success("O código PIX foi gerado com suceso!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setLoading(false);
          } else {
            toast.error("Houve uma falha ao gerar o código PIX. Por favor, tente novamente!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error("Por favor, insira uma mensagem!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });;
      }
    } else {
      toast.error("Por favor, informe seu nome e o seu CPF!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  /*async function copyToClipboard(text) {
    var textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    if(modalPix){
      setModalPix(false);
      setNewMessage({name: newMessage.name, value: 1000, status:"Editando", codePix: "", QRCodePix: "", image: "", video: "", message: ""});
    }
    toast.success("Código PIX copiado com sucesso!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };*/


  function copyToClipboard(text) {
    let textarea, result;
    try {
      textarea = document.createElement('textarea');
      textarea.setAttribute('readonly', true);
      textarea.setAttribute('contenteditable', true);
      textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      const range = document.createRange();
      range.selectNodeContents(textarea);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand('copy');
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }
    // manual copy fallback using prompt
    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
      result = prompt(`Press ${copyHotkey}`, text); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }
    if(modalPix){
      setModalPix(false);
      setNewMessage({name: newMessage.name, value: 1000, status:"Editando", codePix: "", QRCodePix: "", image: "", video: "", message: ""});
    }
    toast.success("Código PIX copiado com sucesso!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    return true;
  }
  
  return (
    <div id="homeLive">
      <Loading visible={loading}/>
      {live.length > 0 ? 
        <div className="container">
          {live[0].business?.banner ?
            <div className="row">
              <div id="bannerChannel" style={{backgroundImage: `url(${bgDefault})`}}></div>
            </div>
          : <></> }
          <div id="bodyLive" className="row v-center">
            <div className="divImageChannel">
              <div id="imageChannel" style={live[0].business?.thumbnail ? {backgroundImage: `url(${live[0].business.thumbnail})`} : {backgroundImage: `url(${logoWhite})`}}></div>
            </div>
            <div className="divNameChannel">
              <div className="title">{live[0].business.name}</div>
              <div>{live[0].business.description}</div>
            </div>
            {live[0]?.description ?
            <div className="col-12">
              <br />
              <div className="title">Observações</div>
              <div style={{whiteSpace:"pre-wrap"}}>{live[0].description}</div>
            </div>
            : <></> }
            <div className="headerChat">
              <div className="title">{live[0].name}</div>
              {live[0]?.link ?
                <a href={`${live[0].link}`} style={{ display: 'flex', overflow: 'hidden'}} target="_Blank" >{live[0].link}</a>
              : <></> }
            </div>
            <div id="chat">
              <div className="bodyChat">
                {messages.length > 0 || myMessages.length > 0 || newMessage?.message  ?
                  <div className="col-12">
                    <br />
                    {messages.map((item, index) => {
                      if(item.myMessage){
                        return (
                          <div key={index} className="col-12 text-left">
                          <div className="row" style={{justifyContent: 'end'}}>
                            <div className={"messageEdit "+Functions.checkMessageColor(parseFloat(item.value))} style={{border: 0}}>
                              <div className="messageUser">{item.name}</div>
                              <div className="messageValue">R$ {Functions.formatMoney(item.value)}</div>
                              {item?.image && item.image !== "" ?
                                <div className="messageImage">
                                  <label style={{ backgroundImage: `url(${item.image})` }}>
                                  </label>
                                </div>
                              : <></> }
                              {item?.video && item.video !== "" ?
                                <div className="messageVideo">
                                  <video controls>
                                    <source src={item.video} type="video/mp4" />
                                  </video>
                                </div>
                              : <></> }
                              <div className="message">{item.message}</div>
                              <div className="messageHour">{item.dateCreated.substr(11,5)} <i className="fa fa-check-double"></i></div>
                              <div className="text-center col-12 col-md-10 offset-md-1">
                                <span type="button" className="btn btn-default-outline btn-pay-message col-12" onClick={() => openModalDetails(item)}> <i className="fa fa-receipt fa-lg"></i> Ver Detalhes </span>
                              </div>
                            </div>
                            <div id="imageUser" style={{backgroundImage: `url(${iconUser})`, marginRight: 0, marginLeft: 15}}></div>
                          </div>
                        </div>
                        )
                      } else {
                        return (
                          <div key={index} className="col-12 text-left">
                            <div className="row">
                              <div id="imageUser" style={{backgroundImage: `url(${iconUser})`}}></div>
                              <div className={"messageSend "+Functions.checkMessageColor(parseFloat(item.value))}>
                                <div className="messageUser">{item.name}</div>
                                <div className="messageValue">R$ {Functions.formatMoney(item.value)}</div>
                                {item?.image && item.image !== "" ?
                                  <div className="messageImage">
                                    <label style={{ backgroundImage: `url(${item.image})` }}>
                                    </label>
                                  </div>
                                : <></> }
                                {item?.video && item.video !== "" ?
                                  <div className="messageVideo">
                                    <video controls>
                                      <source src={item.video} type="video/mp4" />
                                    </video>
                                  </div>
                                : <></> }
                                <div className="message">{item.message}</div>
                                <div className="messageHour">{item.dateCreated.substr(11,5)}</div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })}
                    {myMessages.map((item, index) => {
                      return (
                        <div key={index} className="col-12 text-left">
                          <div className="row" style={{justifyContent: 'end'}}>
                            <div className={"messageEdit "+Functions.checkMessageColor(parseFloat(item.value))} style={{opacity: 0.65, marginTop: 30}}>
                              <div className="messageStatus">
                                <span>{item.status}... </span>
                                <i className="fa fa-spinner fa-pulse"></i>
                              </div>
                              <div className="messageUser">{item.name}</div>
                              <div className="messageValue">R$ {Functions.formatMoney(item.value)}</div>
                              {item?.image && item.image !== "" ?
                                <div className="messageImage">
                                  <label style={{ backgroundImage: `url(${item.image})` }}>
                                  </label>
                                </div>
                              : <></> }
                              {item?.video && item.video !== "" ?
                                <div className="messageVideo">
                                  <video controls>
                                    <source src={item.video} type="video/mp4" />
                                  </video>
                                </div>
                              : <></> }
                              <div className="message">{item.message}</div>
                              <div className="messageHour">{item.dateCreated.substr(11,5)} <i className="fa fa-check"></i></div>
                              <div className="text-center col-12 col-md-10 offset-md-1">
                                <span type="button" className="btn btn-default-outline btn-pay-message col-12" onClick={() => openModalDetails(item)}> <i className="fa fa-receipt fa-lg"></i> Ver Detalhes </span>
                              </div>
                            </div>
                            <div id="imageUser" style={{backgroundImage: `url(${iconUser})`, marginRight: 0, marginLeft: 15}}></div>
                          </div>
                        </div>
                      )
                    })}
                    {(newMessage?.message && newMessage.message !== "") || (newMessage?.image && newMessage.image !== "") || (newMessage?.video && newMessage.video !== "") ?
                      <div ref={refEditMessage} className="col-12 text-left">
                        <div className="row" style={{justifyContent: 'end'}}>
                          <div className={"messageEdit "+Functions.checkMessageColor(parseFloat(newMessage.value/100))} style={{marginTop: 30}/*newMessage.status === "Editando" ? {opacity: 0.65} : {}*/}>
                            <div className="messageStatus">
                              <span>{newMessage.status}... </span>
                              <i className="fa fa-pencil-alt"></i>
                            </div>
                            <div className="messageUser" style={{/*textAlign: "end"*/}}>{newMessage.name}</div>
                            <div className="messageValue" style={{/*right: "auto", left: 0*/}}>R$ {Functions.formatMoney(newMessage.value/100)}</div>
                            {newMessage?.image && newMessage.image !== "" ?
                              <div className="messageImage">
                                <span className="btnDeleteFile" onClick={() => setNewMessage({name: newMessage.name, value: newMessage.value, status:newMessage.status, codePix:newMessage.codePix, QRCodePix: newMessage.QRCodePix, image: "", video: "", message: newMessage.message})}>✘</span>
                                <label style={{ backgroundImage: `url(${preview})` }}>
                                </label>
                              </div>
                            : <></> }
                            {newMessage?.video && newMessage.video !== "" ?
                              <div className="messageVideo">
                                <span className="btnDeleteFile" onClick={() => setNewMessage({name: newMessage.name, value: newMessage.value, status:newMessage.status, codePix:newMessage.codePix, QRCodePix: newMessage.QRCodePix, image: "", video: "", message: newMessage.message})}>✘</span>
                                <video controls>
                                  <source src={previewVideo} type="video/mp4" />
                                </video>
                              </div>
                            : <></> }
                            <div className="message">{newMessage.message}</div>
                            <div className="text-center col-12 col-md-10 offset-md-1">
                              <span type="button" className="btn btn-default-outline btn-pay-message col-12" onClick={openModalPix}> <i className="fa fa-qrcode fa-lg"></i> Pagar via PIX</span>
                            </div>
                          </div>
                          <div id="imageUser" style={{backgroundImage: `url(${iconUser})`, marginRight: 0, marginLeft: 15}}></div>
                        </div>
                      </div>
                    : <></> }
                  </div>
                : <>
                  <div className="col-12 text-center">
                    <br />
                    <br />
                    <br />
                    <p style={{color: '#6e6e6e'}}>Ainda não há mensagens enviadas no chat...</p>
                  </div>
                </> }
              </div>
            </div>
          </div>
          <div className="row" style={{ width: 'inherit', maxWidth: 'inherit'}}>
            <div id="footerLive">
              <form onSubmit={openModalPix}>
                <div className="v-center">
                  {/*<div className="text-center hidden-xs" style={{ width: '10%', justifyContent: 'center'}}>
                    <span type="button" className="btn-send" onClick={()=> {}} title="Enviar Mensagem"> <i className="fa fa-paper-plane fa-2x"></i> </span>
                  </div>*/}
                  <div className="div-input-value col-12 text-center">
                    <div className="col-12">
                      <div className="row" style={{justifyContent: "center"}}>
                        {/*<span className="inputValueLabel">R$ </span>*/}
                        <NumberFormat
                          className="foo"
                          displayType={'text'}
                          format={Functions.currencyFormatter} 
                          prefix={''}
                          //thousandSeparator={false}
                          //decimalSeparator=","
                          //removeFormatting={(formattedValue) => Functions.formatMoney(formattedValue)}
                          renderText={(value, props) => (
                            <input 
                              type="text" 
                              id="inputValue" 
                              //step=".99"
                              min="1" 
                              value={value}
                              onChange={event => setNewMessage({name: newMessage.name, value: event.target.value, status:newMessage.status, codePix:newMessage.codePix, QRCodePix: newMessage.QRCodePix, image: newMessage.image, video: newMessage.video,  message:newMessage.message})}
                            />
                          )}
                          value={newMessage.value}
                          onValueChange={(values) => {
                            const { formattedValue, value, floatValue } = values;
                            //console.log(values);
                            // formattedValue = $2,223
                            // value ie, 2223
                            setNewMessage({name: newMessage.name, value: floatValue, status:newMessage.status, codePix:newMessage.codePix, QRCodePix: newMessage.QRCodePix, image: newMessage.image, video: newMessage.video, message:newMessage.message})
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <input 
                        type="range" 
                        min="0" 
                        max="25000" 
                        value={newMessage.value}
                        onChange={event => setNewMessage({name: newMessage.name, value: event.target.value, status:newMessage.status, codePix:newMessage.codePix, QRCodePix: newMessage.QRCodePix, image: newMessage.image, video: newMessage.video, message:newMessage.message  })}
                        step="500"/>
                    </div>
                  </div>
                  <div className="div-input-comment">
                    <input
                      //required 
                      type="text" 
                      className="form-control" 
                      placeholder="Deixe o seu comentário aqui..."
                      //style={{width: '80%'}}
                      value={newMessage.message}
                      onChange={event => setNewMessage({name: newMessage.name, value: newMessage.value, status:newMessage.status, codePix:newMessage.codePix, QRCodePix: newMessage.QRCodePix, image: newMessage.image, video: newMessage.video, message: event.target.value })}
                      onKeyUp={newMessage.message.length > 2 && messages.length > 0 ? refEditMessage.current.scrollIntoView() : () => {}}
                    />
                    <span type="button" className="btn-input-comment" style={{}} onClick={() => setModalUpload(!modalUpload)} title="Anexar Arquivo"> 
                      { modalUpload ? <i className="fa fa-times"></i> : <i className="fa fa-paperclip"></i>   }
                    </span>
                    <span type="button" className="btn-input-comment" style={{marginRight: 45}} onClick={() => setModalEmogi(!modalEmogi)} title="Emogi"> 
                     { modalEmogi ? <i className="fa fa-times"></i> : <i className="far fa-laugh"></i>  }
                    </span>
                    {modalEmogi ?
                      <Picker 
                        className="modal-emogi" 
                        set='apple' 
                        showPreview={false}
                        showSkinTones={false}
                        autoFocus={false}
                        onClick={(emoji, event) => {
                          console.log(emoji);
                          console.log(event);
                          if(emoji && emoji?.native) {
                            setNewMessage({name: newMessage.name, value: newMessage.value, status:newMessage.status, codePix:newMessage.codePix, QRCodePix: newMessage.QRCodePix, image: newMessage.image, video: newMessage.video, message: newMessage.message + emoji.native});
                            setModalEmogi(!modalEmogi);
                          }
                        }}
                      /> 
                    : <></> }
                    {modalUpload ?
                      <div className="menu-upload"> 
                        <label type="button" className="" title="Adicionar Imagem" onChange={() => setModalUpload(!modalUpload)}> 
                          <i className="fa fa-image fa-lg"></i> 
                          <input className="image-upload" type="file" accept=".jpg, .jpeg, .png" onChange={onChangeImageFile} />
                        </label>
                        <label type="button" className="" title="Adicionar Video" onChange={() => setModalUpload(!modalUpload)}> 
                          <i className="fa fa-video fa-lg"></i> 
                          <input className="image-upload" type="file" accept="video/mp4, video/x-m4v, video/" onChange={onChangeVideoFile} />
                        </label>
                      </div>
                    : <></> }
                  </div>
                  <div className="div-btn-send v-center">
                    <button type="submit" className="btn-send" style={{}}  title="Enviar Mensagem"> <i className="fa fa-paper-plane fa-2x"></i> </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      : <></> }
        <Modal
          isOpen={modalPix}
          //onAfterOpen={afterOpenModal}
          onRequestClose={() => {setModalPix(!modalPix)}}
          style={customStylesModal}
          contentLabel="Pagar via Pix"
        >
          <button className="btnCloseModal" onClick={() => {setModalPix(!modalPix)}}>✘</button>
          <div className="form-group col-12 text-center">
            <br />
            <h4 style={{marginBottom: '1.5rem'}}><b>Pagar via PIX</b></h4>
          </div>
          <form onSubmit={generatePix}>
            <div className="form-group col-12">
              <label className="labelInput">Nome: *</label>
              <input 
                required
                type="text"
                className="form-control" 
                placeholder="Seu nome"
                value={name}
                onChange={event => setName(event.target.value)}
              />
            </div>
            <div className="form-group col-12">
              <label className="labelInput">CPF: *</label>
              <input 
                required
                type="text"
                className="form-control" 
                placeholder="Seu documento de CPF"
                value={CPF}
                onChange={event => setCPF(Functions.cpfMask(event.target.value))}
              />
            </div>
            {/*newMessage?.codePix && newMessage.codePix !== "" ?
              <div className="form-group col-12">
                <label className="labelInput">Código PIX:</label>
                <input 
                  required
                  disabled
                  type="text"
                  className="form-control" 
                  placeholder="Código PIX gerado"
                  value={newMessage.codePix}
                  //onChange={event => setName(event.target.value)}
                />
                <span type="button" className="btn-input" style={{marginTop: -52}} onClick={() => copyToClipboard(newMessage.codePix)} title="Copiar Link"> <i className="fa fa-copy"></i> </span>
              </div>
              : <></> */}
            <br />
            <div className="form-group col-12 text-center">
              <button type="submit" className="btn btn-default"> Gerar Código PIX </button>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={modalDetails}
          //onAfterOpen={afterOpenModal}
          onRequestClose={() => {setModalDetails(!modalDetails)}}
          style={customStylesModal}
          contentLabel="Detalhes"
        >
          <button className="btnCloseModal" onClick={() => {setModalDetails(!modalDetails)}}>✘</button>
          <div className="form-group col-12 text-center">
            <br />
            <h4 style={{marginBottom: '1.5rem'}}><b>Detalhes do Comentário</b></h4>
          </div>
          {messageSelected?.dateCreated && messageSelected.dateCreated !== "" ?
            <div className="form-group">
              <div>Usuário: <b>{messageSelected.name}</b></div>
              {/*<div>CPF: <b>{messageSelected.CPF}</b></div>*/}
              <div>Valor: <b>R$ {Functions.formatMoney(messageSelected.value)}</b></div>
              <div>Status: <b>{messageSelected.status === "Pago" ? "Pago / Publicado" :messageSelected.status}</b></div>
              <div>Data/Hora: <b>{messageSelected.dateCreated.substr(0,10).split("-").reverse().join("/")} às {messageSelected.dateCreated.substr(11,5)}h</b></div>
            </div>
           : <></> }
          {messageSelected?.status && messageSelected.status !== "Pago" ?
          <>
            <div className="form-group text-center">
              <img src={`data:image/png;base64, ${messageSelected.QRCodePix}`} width="200px"/>
            </div>
            <div className="form-group">
              <label className="labelInput">Código PIX:</label>
              <input 
                contenteditable="true"
                required
                disabled
                type="text"
                className="form-control" 
                placeholder="Código PIX gerado"
                value={messageSelected.codePix}
                //onChange={event => setName(event.target.value)}
              />
              <span type="button" className="btn-input" style={{marginTop: -52}} onClick={() => copyToClipboard(messageSelected.codePix)} title="Copiar Link"> <i className="fa fa-copy"></i> </span>
            </div>
            <br />
            <div className="form-group col-12 text-center">
              <button type="button" className="btn btn-default" onClick={() => copyToClipboard(messageSelected.codePix)}> Copiar Código PIX </button>
            </div>
          </> 
          : <></> }
        </Modal>
        <ToastContainer />
    </div>
  );
 
}