import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Login from './pages/Login';
import Register from './pages/Register';
import RegisterSuccess from './pages/RegisterSuccess';
import Dashboard from './pages/Dashboard';
import Index from './pages/Index';
import Live from './pages/Live';

export default function Routes() {
  return (
    <BrowserRouter>
      {/*Switch: Apenas uma rota é executada por vez */}
      {/* exact: rota exata */}
      <Switch>
        <Route path="/" exact component={Index} />
        <Route path="/creator" exact component={Login} />
        <Route path="/creator/login" exact component={Login} />
        <Route path="/creator/dashboard" component={Dashboard} />
        <Route path="/creator/register" exact component={Register} />
        <Route path="/creator/register-success" exact component={RegisterSuccess} />
        <Route path="/live" component={Live} /> 
        <Redirect to="/" /> {/*Qualquer outra rota redireciona para a index*/}
      </Switch>
    </BrowserRouter>
  );
}