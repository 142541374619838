//useEffect = Método chamado ao carragar a página;
import React, { useContext, useEffect, useState} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import ListItemLive from '../../components/ListItemLive'
import './styles.css';
import '../../App.css';
import UserContext from '../../contexts/UserContext';

export default function DashboardLives({setLoading}) {
  const [lives, setLives] = useState([]);
  const [allLives, setAllLives] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const {user, setUser} = useContext(UserContext);
  const [filterSelected, setFilterSelected] = useState({});
  const history = useHistory();

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadBusinesses();
  }, []);

  async function loadBusinesses() {
    try {
      const response = await Api.get(`/get-business-user/${user[0]._id}`);
      console.log(response);
      if(response && response.length > 0) {
        setBusinesses(response);
        setFilterSelected(response[0]);
        loadLives(response[0]);
      } else {
        toast.error("Você primeiro precisa adicionar o seu canal!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        history.push('/creator/dashboard/meus-canais?add');
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function loadLives(business) {
    try {
      const response = await Api.get("/get-lives-business/"+business._id);
      setLives(response);
      setAllLives(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  function onChangeFilter(filter, allLives){
    var objFilter = JSON.parse(filter);
    console.log(objFilter);
    setFilterSelected(objFilter);
    var arrFiltered = allLives.filter((item) => {
      return item._id === objFilter.id;
    });
    console.log(JSON.parse(filter));
    loadLives(JSON.parse(filter));
  }
  
  async function addLive(){
    const liveCopy = Array.from(lives);
    var liveID = filterSelected._id.substr((filterSelected._id.length-2), 2) + new Date().getTime().toString(36).slice(3).toLowerCase() + Math.random().toString(36).slice(-2).toLowerCase();
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    liveCopy.unshift({
      _id: liveCopy.length +1,
      liveID: liveID,
      name: "",
      description: "",
      link: "",
      business: filterSelected._id,
      //thumbnail: "",
      active: true
    });
    await setLives(liveCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(liveCopy.length)
  }

  async function removeLive(id){
    setLoading(true);
    const liveCopy = Array.from(lives);
    var index = liveCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.post('/delete-live', {id: id});
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          liveCopy.splice(index, 1);
          setLives(liveCopy);
        } else {
          if(response?.message){
            toast.error(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error("Falha ao realizar a operação!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    } else {
      liveCopy.splice(index, 1);
      setLives(liveCopy);
    }
    setLoading(false);
  }

  return (
    <div id="liveList" className="bodyDashboard">
      <br />
      <div className="v-center">
        <div className="col-6">
          {/*<span className="title">Live Streams</span>*/}
          <select className="title" style={{border: 0, background: 'transparent', cursor: 'pointer'}} onChange={event => onChangeFilter(event.target.value, allLives)}>
          {/*<option value={filterSelected}>{filterSelected.name}</option>*/}
            {businesses.map((item, index) => (
              <option key={index} value={JSON.stringify(item)}>{item.name}</option>
            ))}
          </select>
        </div>
        <div className="col-6 text-right">
          {/*<button type="button" onClick={addLive} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>*/}
          <button type="button" onClick={addLive} className="btn btn-default"> Adicionar Live </button>
        </div>
      </div>
        <div className="col-12">
          <br />
          <ul className="dashboardList">
            {lives.length > 0 ?
            lives.map(item => (
              <ListItemLive
                key={item._id}
                id={item._id}
                liveID={item.liveID}
                name={item.name}
                description={item.description}
                //thumbnail={item.thumbnail} 
                link={item.link}
                business={item.business} 
                active={item.active} 
                onDelete={() => removeLive(item._id)}
                loadItem={() => loadLives(filterSelected)}
                setLoading={setLoading}
                //ref={childRef}
              />
            )) :
            <div className="col-12 text-center">
              <br /><br /><br />
              <span className="labelFilter">Você ainda não possui Live Streams adicionadas!</span>
            </div>}
          </ul>
      </div>
    </div>
  )
 
}