//useEffect = Método chamado ao carragar a página;
import React, { useContext, useEffect, useState} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone'
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
//import ListItemFinance from '../../components/ListItemFinance'
import './styles.css';
import '../../App.css';
import UserContext from '../../contexts/UserContext';
import Functions from '../../components/Functions'
import Modal from 'react-modal';
Modal.setAppElement(document.getElementById('root'));

export default function DashboardFinances({setLoading}) {
  const [businesses, setBusinesses] = useState([]);
  const [resumeFinances, setResumeFinances] = useState([{totalDisponivel: 0, totalLiberar: 0, totalEmAberto: 0, totalBloqueado: 0}]);
  const [transactions, setTransactions] = useState([]);
  const [finances, setFinances] = useState([]);
  const [allFinances, setAllFinances] = useState([]);
  const {user, setUser} = useContext(UserContext);
  const [filterSelected, setFilterSelected] = useState({});
  const [valueTransfer, setValueTransfer] = useState(0);
  const [modalTransfer, setModalTransfer] = useState(false);
  const history = useHistory();

  Moment.globalMoment = moment;
  Moment.globalTimezone = 'America/Sao_Paulo';

  const customStylesModal = {
    overlay: {
      background: 'rgba(0,0,0,0.5)',
      zIndex: 999
    }, 
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '95%',
      maxWidth: '550px',
      border: 0,
      padding: '15px',
      borderRadius: '15px',
      boxShadow: '0px 0px 5px -2px #bbbbbb'
    }
  };
  // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadBusinesses();
  }, []);

  async function loadBusinesses() {
    try {
      const response = await Api.get(`/get-business-user/${user[0]._id}`);
      console.log(response);
      if(response && response.length > 0) {
        setBusinesses(response);
        setFilterSelected(response[0]);
        //loadFinances(response[0]._id);
        loadCommentsBusiness(response[0]);
      } else {
        toast.error("Você primeiro precisa adicionar o seu canal!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        history.push('/creator/dashboard/meus-canais?add');
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function loadCommentsBusiness(selectedBusiness) {
    try {
      console.log("Buscando comentários do canal --> " + new Date());
      const dataHoraAtual = await Api.get("/data-hora-atual");
      console.log(dataHoraAtual.dataHora);
      const response = await Api.get("/get-comments-business/"+selectedBusiness._id);
      var allTransactions = await loadTransactions(selectedBusiness._id);
      var arrFinances = [], arrTransactionsLive = [], totalDisponivel = 0, totalLiberar = 0, totalEmAberto = 0, totalBloqueado = 0;
      console.log(allTransactions);
      console.log(response);
      setFinances(response);
      if(response.length > 0){
        await response.forEach(async (item) => {
          //Desconta o percentual do plano;
          //var valueItem = item.value - ((selectedBusiness.plan.percentage/100) * item.value);
          if(item.status === "Pago"){
            //console.log(moment(item.dateCreated).add(selectedBusiness.plan.days, 'days'));
            //console.log(moment(dataHoraAtual.dataHora));
            //console.log(moment(item.dateCreated).add(selectedBusiness.plan.days, 'days') <= moment(dataHoraAtual.dataHora));
            //moment(item.dateCreated).add(selectedBusiness.plan.days, 'days')
            if(moment(item.datePaymentLiberate) <= moment(dataHoraAtual.dataHora)) {
              totalDisponivel += item.valueBusiness;
            } else {
              totalLiberar += item.valueBusiness;
            }
            //Adiciona os comentários das lives ao array de transações
            let index = arrTransactionsLive.findIndex(x => x["_id"] === item.liveID);
            if(index > -1) {
              arrTransactionsLive[index].value += item.valueBusiness;
            } else {
              arrTransactionsLive.push({_id: item.liveID, status: "Comentários da live: " + item.liveID, value: item.valueBusiness, operation: "E", dateCreated: item.dateCreated});
            }
          } else if(item.status === "Aguardando Pagamento"){
            totalEmAberto += item.valueBusiness;
          }
        });
        console.log(arrTransactionsLive);
        allTransactions.forEach(async (item) => {
          if(item.status === "Transferência Solicitada"){
            totalDisponivel = totalDisponivel - item.value;
            totalBloqueado += item.value;
          }
          if(item.status === "Transferência Concluída"){
            totalDisponivel = totalDisponivel - item.value;
          }
        });
        allTransactions = [...allTransactions, ...arrTransactionsLive];
        var arrSort = await allTransactions.sort(function(a, b) {
          return new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime();
        });
        arrSort.reverse();
        //console.log(allTransactions);
        console.log(arrSort);
        setTransactions(arrSort);
        arrFinances.push({
          totalDisponivel: Number(parseFloat(totalDisponivel).toFixed(2)), 
          totalLiberar: Number(parseFloat(totalLiberar).toFixed(2)), 
          totalEmAberto: Number(parseFloat(totalEmAberto).toFixed(2)), 
          totalBloqueado: Number(parseFloat(totalBloqueado).toFixed(2))
        });
        console.log(arrFinances);
        setResumeFinances(arrFinances);
        setValueTransfer(arrFinances[0].totalDisponivel);
      } else {
        setResumeFinances([{totalDisponivel: 0, totalLiberar: 0, totalEmAberto: 0, totalBloqueado: 0 }]);
        setValueTransfer(0);
        setTransactions(allTransactions);
      }
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function loadTransactions(idBusiness) {
    try {
      const response = await Api.get(`/get-transactions/${idBusiness}`);
      console.log(response);
      //setTransactions(response);
      setLoading(false);
      return response;
    } catch (error) {
      console.log(error)
      return [];
    }
  }

  function onChangeFilter(filter, allFinances){
    var objFilter = JSON.parse(filter);
    console.log(objFilter);
    setFilterSelected(objFilter);
    var arrFiltered = allFinances.filter((item) => {
      return item._id === objFilter.id;
    });
    setFinances(arrFiltered);
    console.log(JSON.parse(filter))
    loadCommentsBusiness(JSON.parse(filter));
  }
  
  async function newTransfer(e){
    e.preventDefault();
    if(valueTransfer !== 0 && valueTransfer <= resumeFinances[0].totalDisponivel){
      setLoading(true);
      const data = {
        business: filterSelected._id,
        user: user[0]._id,
        operation: "S",
        value: valueTransfer,
        keyPIX: filterSelected.keyPIX
      }
      try {
        const response = await Api.post("/store-transaction", data);
        console.log(response);
        if(response.hasOwnProperty('_id') || response?.ok){
          toast.success("Transferência solicitada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setModalTransfer(false);
          loadCommentsBusiness(filterSelected);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setModalTransfer(false);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Você não possui este valor disponível para transferência!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  return (
    <div id="financeList" className="bodyDashboard">
      <br />
      <div className="v-center">
        <div className="col-12 col-md-6">
          {/*<span className="title">Finance Streams</span>*/}
          <select className="title" style={{border: 0, background: 'transparent', cursor: 'pointer'}} onChange={event => onChangeFilter(event.target.value, allFinances)}>
          {/*<option value={filterSelected}>{filterSelected.name}</option>*/}
            {businesses.map((item, index) => (
              <option key={index} value={JSON.stringify(item)}>{item.name}</option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-6 text-right">
          {/*<button type="button" onClick={addFinance} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
          <button type="button" onClick={addFinance} className="btn btn-default"> Adicionar Finance </button>*/}
        </div>
      </div>
      <div className="col-12">
        <br />
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="itemFinance disponivel">
              <p className="financeLabel">Valor disponível</p>
              <div className="row">
                <div className="col-7">
                  <span className="financeValue">R$ {Functions.formatMoney(resumeFinances[0].totalDisponivel)}</span>
                </div>
                <div className="col-5">
                  {resumeFinances[0].totalDisponivel > 0 ?
                    <div className="text-right">
                      <button type="button" onClick={()=> {setModalTransfer(!modalTransfer)}} className="btn btn-transfer"> Tranferir </button>
                    </div>
                  : <></> }
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="itemFinance liberar">
              <p className="financeLabel">Valor à liberar</p>
              <div className="text-left">
                <span className="financeValue">R$ {Functions.formatMoney(resumeFinances[0].totalLiberar)}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="itemFinance bloqueado">
              <p className="financeLabel">Valor Bloqueado</p>
              <div className="text-left">
                <span className="financeValue">R$ {Functions.formatMoney(resumeFinances[0].totalBloqueado)}</span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="itemFinance emAberto">
              <p className="financeLabel">Valor Pendente</p>
              <div className="text-left">
                <span className="financeValue">R$ {Functions.formatMoney(resumeFinances[0].totalEmAberto)}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="itemLegenda v-center"> <div className="iconLegenda" style={{backgroundColor: "#2ac705"}}></div> Disponível para tranferir </div>
            <div className="itemLegenda v-center"> <div className="iconLegenda" style={{backgroundColor: "#009ce2"}}></div> Aguardando liberação </div>
            <div className="itemLegenda v-center"> <div className="iconLegenda" style={{backgroundColor: "#ff0000"}}></div> Em processo de transferência </div>
            <div className="itemLegenda v-center"> <div className="iconLegenda" style={{backgroundColor: "#fde122"}}></div> Pagamentos em aberto </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-12">
            <span className="title">Transações</span>
          </div>
          <ul className="dashboardList col-12 listTransactions">
            {transactions.length > 0 ?
              transactions.map(item => (
                <div key={item._id} className={item.status === "Transferência Solicitada" ? "itemFinance emAberto" : "itemFinance disponivel"}>
                  <div className="row v-center">
                    <div className="col-8">
                      <div className="title" style={{fontSize: 16}}>{item.status}</div>
                      {item?.keyPIX ?
                        <span className="" style={{fontSize: 12}}>Transferência para o PIX: {item.keyPIX}</span>
                      : 
                        <span className="" style={{fontSize: 12}}>Valor total recebido na live</span>
                      }
                    </div>
                    <div className="col-4 text-right">
                      <div className="title" style={{fontSize: 16}}>{item.operation === "S" ? " - " : ""} R$ {Functions.formatMoney(item.value)}</div>
                      <span className="" style={{fontSize: 12}}>{item.dateCreated.substr(0,10).split("-").reverse().join("/")}</span>
                    </div>
                  </div>
                </div>
              )) :
              <div className="col-12 text-center">
                <br /><br />
                <span className="labelFilter">Este canal ainda não possui transações financeiras!</span>
              </div>
            }
          </ul>
        </div>
      </div>
      <Modal
        isOpen={modalTransfer}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setModalTransfer(!modalTransfer)}}
        style={customStylesModal}
        contentLabel="Transferir"
      >
        <button className="btnCloseModal" onClick={() => {setModalTransfer(!modalTransfer)}}>✘</button>
        <div className="form-group col-12 text-center">
          <br />
          <h4 style={{marginBottom: '1.5rem'}}><b>Solicitar Transferência</b></h4>
        </div>
        <form onSubmit={newTransfer}>
          <div className="form-group col-12">
            <label className="labelInput">Chave PIX: *</label>
            <input 
              required
              disabled={"disabled"}
              type="text"
              className="form-control" 
              value={filterSelected.keyPIX}
            />
          </div>
          <div className="form-group col-12">
            <label className="labelInput">Valor da Transferência: *</label>
            <input 
              required
              type="number"
              min="1" 
              step="0.01" 
              className="form-control" 
              placeholder="Informe o valor que deseja transferir"
              value={valueTransfer}
              onChange={event => setValueTransfer(event.target.value)}
            />
          </div>
          <br />
          <div className="form-group col-12 text-center">
            <button type="submit" className="btn btn-default"> Solicitar Transferência </button>
          </div>
        </form>
      </Modal>
    </div>
  )
 
}