//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext } from 'react';
import { GiBullseye } from "react-icons/gi";
import { FiShoppingBag } from "react-icons/fi";
import { FaUsers, FaSatelliteDish, FaSignOutAlt, FaFileInvoiceDollar, FaPlayCircle } from "react-icons/fa";
//import { Link } from 'react-router-dom';
//import Api from '../../services/api'
import UserContext from '../../contexts/UserContext';
import ListItemBusiness from '../../components/ListItemBusiness';
import blank from '../../assets/icons/blank.png';
import './styles.css';
import Api from '../../services/Api';
import iconProfileWhite from '../../assets/icons/icon-profile-white.png';

export default function DashboardHome(props) {
  const [loading, setLoading] = useState(true);
  const [businesses, setBusinesses] = useState([]);
  const [editBusiness, setEditBusiness] = useState(false);
  const {user, setUser} = useContext(UserContext);

  // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadBusinesses();
  }, []);

  async function loadBusinesses() {
    try {
      const response = await Api.get(`/get-business-user/${user[0]._id}`);
      console.log(response);
      setBusinesses(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function addBusiness(){
    const businessCopy = Array.from(businesses);
    setEditBusiness(true);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    businessCopy.unshift({
      _id: businessCopy.length +1,
      user: user[0]._id,
      item: "Business",
      name: "",
      description: "",
      //thumbnail: "",
      city: "",
      state: "",
      address: "",
      CNPJ: "",
      razaoSocial: "",
      active: true
    });
    await setBusinesses(businessCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(businessCopy.length)
  }

  async function removeBusiness(id){
    setLoading(true);
    const businessCopy = Array.from(businesses);
    var index = businessCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.post('/delete-business', {'id': id});
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          businessCopy.splice(index, 1);
          setBusinesses(businessCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      businessCopy.splice(index, 1);
      setBusinesses(businessCopy);
    }
    setLoading(false);
  }

  return (
    <div className="bodyDashboard">
      <div className="itensHome">
        <div className="col-12">
          <br />
          <div className="v-center"> 
            <label id="imgUser" style={user[0].hasOwnProperty("thumbnail") ? { backgroundImage: `url(${user[0].thumbnail})` } : { backgroundImage: `url(${iconProfileWhite}`}}></label>
            <div style={{marginLeft: 15, paddingTop: 12, paddingBottom: 12}}>
              <div><b style={{fontSize: 20}}>{user[0].name}</b></div>
              <div>{user[0].email}</div>
              <button type="button" onClick={() => props.setModalPassword(!props.modalPassword)} className="btnLink">Alterar Senha?</button>
            </div>
          </div>
          <br />
        </div>
        {/*<h3 className="title">Meus Canais</h3>*/}
        {businesses.length > 0 ? 
          <div className="row">
            <div className="col-12 col-md-6 text-center">
              <div className="itemHome" onClick={() => props.history.push('/creator/dashboard/meus-canais')}>
                <FaPlayCircle size="5em" className="itemHomeIcon"/>
                <div className="title"> Meus Canais </div>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="itemHome" onClick={() => props.history.push('/creator/dashboard/live-streams')} >
                <FaSatelliteDish size="5em" className="itemHomeIcon"/>
                <div className="title"> Live Streams </div>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="itemHome" onClick={() => props.history.push('/creator/dashboard/financeiro')}>
                <FaFileInvoiceDollar size="5em" className="itemHomeIcon"/>
                <div className="title"> Financeiro </div>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="itemHome" onClick={() => props.callbackParent(4)}>
                <FaSignOutAlt size="5em" className="itemHomeIcon"/>
                <div className="title"> Sair </div>
              </div>
            </div>
          </div>
        : <>
          <div className="col-12 text-center">
            <img src={blank} width="100%"/>
            <p>Você ainda não adicionou o seu canal!</p>
            <button type="button" onClick={() => props.history.push('/creator/dashboard/meus-canais?add')} className="btn btn-default"> Adicionar Canal </button>
          </div>
        </> }

        {user[0].hasOwnProperty("admin") && user[0].admin ? <>
          
        </> : <>
          {/*<div className="col-12">
            <br />
            <div className="row v-center">
              <div className="col-7">
                <span className="title">Meus Canais</span>
              </div>
              <div className="col-5 text-right">
                <button type="button" onClick={addBusiness} className="btn btn-default"> Adicionar Canal </button>
              </div>
            </div>
            {
              businesses.length > 0 ?
              businesses.map((item) => (
                <div key={item._id} className="itemHome" style={{padding: 0, cursor: "initial"}}>
                  {editBusiness ?
                    <div className="col-12">
                      <ul className="dashboardEditbusiness"> 
                        <ListItemBusiness
                          item={item?.item ? item.item : "editBusiness"}
                          id={item._id}
                          user={user[0]._id}
                          name={item.name}
                          description={item.description}
                          thumbnail={item.thumbnail} 
                          city={item.city} 
                          state={item.state} 
                          address={item?.address ? item.address : ""} 
                          CNPJ={item?.CNPJ ? item.CNPJ : ""}
                          razaoSocial={item?.razaoSocial ? item.razaoSocial : ""} 
                          active={item.active} 
                          onDelete={() => removeBusiness(item._id)}
                          loadItem={() => loadBusinesses()}
                          setEditBusiness={(value) => setEditBusiness(value)}
                          setLoading={setLoading}
                        />
                      </ul>
                    </div>
                  : <>
                    <div className="v-center"> 
                      <label id="imgBusiness" style={{ backgroundImage: `url(${item.thumbnail})` }}></label>
                      <div style={{marginLeft: 15, paddingTop: 12, paddingBottom: 12}}>
                        <div><b style={{fontSize: 20}}>{item.name}</b></div>
                        <div>{item.city} ({item.state})</div>
                        <button type="button" onClick={() => setEditBusiness(!editBusiness)} className="btn btn-default" style={{marginTop: 20}}>Editar Canal</button>
                        {/*<button type="button" onClick={() => props.callbackParent(2)} className="btn btn-default" style={{marginTop: 20}}>Gerenciar Moradores</button>*}
                      </div>
                    </div>
                  </>}
                </div>
              )) :
              <div className="col-12 text-center">
                <br /><br /><br />
                <span className="labelFilter">Você ainda não possui canais cadastrados!</span>
              </div>
            }
          </div>*/}
        </> }
      </div>
    </div>
  )
 
}