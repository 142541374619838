//useEffect = Método chamado ao carragar a página;
import React, { useContext, useEffect, useState} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { Link } from 'react-router-dom';
//import { GiBullseye } from "react-icons/gi";
import { FaUsers, FaUsersCog, FaBullhorn, FaHome, FaSignOutAlt, FaRegPaperPlane, FaFileInvoiceDollar, FaBuilding, FaPlayCircle, FaSatelliteDish, FaCalendarDay, FaSignInAlt } from "react-icons/fa";
import logo from '../../assets/logo-home.png';
import Api from '../../services/Api'
import DashboardHome from './DashboardHome';
import DashboardUsers from './DashboardUsers';
import DashboardBusiness from './DashboardBusiness';
import DashboardLives from './DashboardLives';
import DashboardFinances from './DashboardFinances';
import Loading from '../../components/Loading';
import UserContext from '../../contexts/UserContext';
import Functions from '../../components/Functions';
import './styles.css';

import Modal from 'react-modal';

Modal.setAppElement(document.getElementById('root'));

export default function Dashboard({ history }) {
  const [loading, setLoading] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [listMenuActive, setListMenuActive] = useState(0);
  const [modalPassword, setModalPassword] = useState(false);
  const [pass, setPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newPassConfirm, setNewPassConfirm] = useState('');
  const [modalEmail, setModalEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [file, setFile] = useState([]);
  const {user, setUser} = useContext(UserContext);
  const location = useLocation();

  const customStylesModal = {
    overlay: {
      background: 'rgba(0,0,0,0.5)',
      zIndex: 999
    }, 
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '95%',
      maxWidth: '550px',
      border: 0,
      padding: '15px',
      borderRadius: '15px',
      boxShadow: '0px 0px 5px -2px #bbbbbb'
    }
  };

  useEffect(() => {
    console.log(user);
    const user_id = sessionStorage.getItem('user');
    if(user_id) {
      validateLogin(user_id);
    } else {
      history.push('/creator/login');
    }
  }, []);

  useEffect(() => {
    console.log('Change history...');
    if(history.location.pathname.indexOf('/meus-canais') !== -1){
      optionMenu(7);
    }
    if(history.location.pathname.indexOf('/live-streams') !== -1){
      optionMenu(8);
    }  
    if(history.location.pathname.indexOf('/financeiro') !== -1){
      optionMenu(9);
    }
  }, [location]);

  /*useEffect(() => {
    optionMenu(listMenuActive);
  }, [listMenuActive]);*/

  async function validateLogin(user_id) {
    if(user_id){
      const response = await Api.getUser(user_id);
      if(response && response.hasOwnProperty('_id')){
        console.log("Usuário logado!")
        console.log(response);
        setUser([response]);
        setLoading(false);
      } else {
        history.push('/creator/login');
        setUser([]);
      }
    }
  }

  function onChangeFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      //let file_name = e.target.files[0].name;
      //let file_type = e.target.files[0].type;
      //console.log('executou o file change: ' +  file_size);
      //Se maior que um 1mb(1048576)
      if(parseInt(file_size) >= 1048000) {
        toast.error("Arquivo não suportado! O arquivo deve ter no máximo 1MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }else{
        setFile(e.target.files[0]);
      }
    }
  };

  async function sendEmail(e){
    e.preventDefault();
    if(email !== "" && Functions.validateEmail(email) && subject !== "" && body !== ""){
      const data = new FormData();
      data.append('to', email);
      data.append('subject', subject);
      data.append('mail', body);
      if(file && typeof file === "object")
        data.append('file', file);
      const response = await Api.sendEmail(data);
      console.log(response);
      if(response && response.status === 200){
        setModalEmail(false);
        toast.success('Email enviado com sucesso!', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setEmail('');
        setSubject('');
        setBody('');
        setFile([]);
      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      toast.error('Por favor, preencha corretamente os campos obrigatórios (*)', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  async function changePass(e){
    e.preventDefault();
    if(pass && pass !== "" && newPass && newPass !== "" && newPassConfirm && newPassConfirm !== ""){
      if(newPass === newPassConfirm && newPass !== ""){
        if(newPass.length >= 6){
          const response = await Api.updatePass(user[0]._id, pass.trim(), newPass.trim());
          console.log(response);
          if(response?.nModified && response.nModified === 1){
            setModalPassword(false);
            toast.success('Senha alterada com sucesso!', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setPass('');
            setNewPass('');
            setNewPassConfirm('');
          } else {
            toast.error(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          toast.error('A senha deve conter ao menos 6 caracteres!', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } else {
        toast.error('As senhas informadas não conferem!', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      toast.error('Por favor, preencha todos os campos!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  function logout(){
    sessionStorage.removeItem('user');
    setUser([]);
    history.push('/');
  }

  function optionMenu(menu){
    if(menu === 1) {
      setListMenuActive(1);
    } else if(menu === 2) {
      setListMenuActive(2);
    } else if(menu === 3) {
      setListMenuActive(3);
    } else if(menu === 5) {
      setListMenuActive(5);
    } else if(menu === 6) {
      setListMenuActive(6);
    } else if(menu === 7) {
      setListMenuActive(7);
    } else if(menu === 8) {
      setListMenuActive(8);
    } else if(menu === 9) {
      setListMenuActive(9);
    } else if(menu === 10) {
      setListMenuActive(10);
    } else if(menu === 4) {
      logout();
    } else {
      setListMenuActive(0);
    }
    setToggleMenu(false);
  }
  
  if(user.length > 0){
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 barraSuperior">
              <div className="row v-center">
                <div className="col-4 text-left logo">
                  <img id="logo-barra" src={logo} width="100%" onClick={() => {history.push('/creator/dashboard'); optionMenu(0)}} style={{cursor: "pointer"}}/>
                </div>
                <div className="col-4 text-center">
                  <span id="titleMenu"> 
                    {listMenuActive === 0 ? 'Home' : ''}
                    {listMenuActive === 2 ? 'Usuários' : ''}
                    {listMenuActive === 7 ? 'Meus Canais' : ''}
                    {listMenuActive === 6 ? 'Banners' : ''}
                    {listMenuActive === 8 ? 'Live Streams' : ''}
                    {listMenuActive === 9 ? 'Financeiro' : ''}
                  </span>
                </div>
                <div className="col-4 text-right imgMenu">
                  <span id="btnMenu" className={toggleMenu ? "fa fa-times fa-2x" : "fa fa-bars fa-2x"} style={{fontSize: '2.2em'}} onClick={() => toggleMenu ? setToggleMenu(false) : setToggleMenu(true)} alt="Menu" ></span>
                </div>
              </div>
          </div>
        </div>
        <ul id="menuUser" className={`${toggleMenu ? 'active' : ''}`}>
          <li onClick={() => optionMenu(0)} className={`${listMenuActive === 0 ? 'active' : ''}`}><FaHome size="2em" className="itemHomeIcon"/> Home</li>
          <li onClick={() => history.push('/creator/dashboard/meus-canais')} className={`${listMenuActive === 7 ? 'active' : ''}`}><FaPlayCircle size="2em" className="itemHomeIcon"/> Meus Canais </li>
          <li onClick={() => history.push('/creator/dashboard/live-streams')} className={`${listMenuActive === 8 ? 'active' : ''}`}><FaSatelliteDish size="2em" className="itemHomeIcon"/> Live Streams </li>
          <li onClick={() => history.push('/creator/dashboard/financeiro')} className={`${listMenuActive ===  9 ? 'active' : ''}`}><FaFileInvoiceDollar size="2em" className="itemHomeIcon"/> Financeiro </li> 
          {/*<li onClick={() => optionMenu(2)} className={`${listMenuActive === 2 ? 'active' : ''}`}><FaUsers size="2em" className="itemHomeIcon"/> Usuários </li>*/}
          <li onClick={() => optionMenu(4)} className={`${listMenuActive === 4 ? 'active' : ''}`}><FaSignOutAlt size="2em" className="itemHomeIcon"/> Sair</li>
        </ul>
        <Loading visible={loading}/>
        <div className="row v-center">
          {listMenuActive === 0 ? <DashboardHome history={history} callbackParent={(menu) => optionMenu(menu)} loading={loading} setLoading={(value) => setLoading(value)} modalPassword={modalPassword} setModalPassword={(value) => setModalPassword(value)} reloadUser={() => validateLogin(user[0]._id)}/> : ''}
          {listMenuActive === 7 ? <DashboardBusiness history={history} loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
          {listMenuActive === 2 ? <DashboardUsers history={history} loading={loading} setLoading={(value) => setLoading(value)} sendEmail={(value) => {setEmail(value); setModalEmail(!modalEmail)}}/> : ''}
          {listMenuActive === 8 ? <DashboardLives history={history} loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
          {listMenuActive === 9 ? <DashboardFinances history={history} loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
        </div>
        <Modal
          isOpen={modalPassword}
          //onAfterOpen={afterOpenModal}
          onRequestClose={() => {setModalPassword(!modalPassword)}}
          style={customStylesModal}
          contentLabel="Alterar Minha Senha"
        >
          <button className="btnCloseModal" onClick={() => {setModalPassword(!modalPassword)}}>✘</button>
          <div className="form-group col-12 text-center">
            <br />
            <h4 style={{marginBottom: '1.5rem'}}><b>Alterar Minha Senha</b></h4>
          </div>
          <form onSubmit={changePass}>
            <div className="form-group col-12">
              <label className="labelInput">Senha atual: *</label>
              <input 
                type="password"
                className="form-control" 
                placeholder="Informe sua senha atual"
                value={pass}
                onChange={event => setPass(event.target.value)}
              />
            </div>
            <div className="form-group col-12">
              <label className="labelInput">Nova senha: *</label>
              <input 
                type="password"
                className="form-control" 
                placeholder="Insira sua nova senha"
                value={newPass}
                onChange={event => setNewPass(event.target.value)}
              />
            </div>
            <div className="form-group col-12">
              <label className="labelInput">Confirme sua nova senha: *</label>
              <input 
                type="password" 
                className="form-control" 
                placeholder="Confirme sua nova senha"
                value={newPassConfirm}
                onChange={event => setNewPassConfirm(event.target.value)}
              />
            </div>
            <br />
            <div className="form-group col-12 text-center">
              <button type="submit" className="btn btn-default"> Alterar Senha </button>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={modalEmail}
          //onAfterOpen={afterOpenModal}
          onRequestClose={() => {setModalEmail(!modalEmail)}}
          style={customStylesModal}
          contentLabel="Enviar E-mail"
        >
          <button className="btnCloseModal" onClick={() => {setModalEmail(!modalEmail)}}>✘</button>
          <div className="form-group col-12 text-center">
            <br />
            <h4 style={{marginBottom: '1.5rem'}}><b>Enviar E-mail</b></h4>
          </div>
          <form onSubmit={sendEmail}>
            <div className="form-group col-12">
              <label className="labelInput">Email: *</label>
              <input 
                required
                type="text"
                className="form-control" 
                placeholder="Informe o endereço de e-mail"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </div>
            <div className="form-group col-12">
              <label className="labelInput">Assunto: *</label>
              <input 
                required
                type="text"
                className="form-control" 
                placeholder="Informe o assunto do e-mail"
                value={subject}
                onChange={event => setSubject(event.target.value)}
              />
            </div>
            <div className="form-group col-12">
              <label className="labelInput">Mensagem: *</label>
              <textarea
                required
                type="text"  
                className="form-control" 
                rows="3"
                placeholder="Insira aqui sua mensagem... "
                value={body}
                onChange={(event) => setBody(event.target.value)}
              />
            </div> 
            <div className="form-group col-12">
              <label className="labelInput">Enviar Anexo: *</label>
              <input type="file" className="form-control" onChange={onChangeFile} style={{fontSize: 14}}/>
            </div>
            <br />
            <div className="form-group col-12 text-center">
              <button type="submit" className="btn btn-default"> Enviar E-mail </button>
            </div>
          </form>
        </Modal>
        <ToastContainer />
      </div>
    );
  } else {
    /*return (<div>Falha ao validar o usuário logado, você será redirecionado em...</div>)*/
    return (<div></div>)
  }
 
}