import React, { useState, useContext } from 'react';
import { sendNotification } from './Functions'
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../services/Api'
import { FaEnvelope, FaRegPaperPlane } from 'react-icons/fa';
import UserContext from '../contexts/UserContext';

const ListItemUser = ({ id, token, item, name, thumbnail, email, servico, plan, condominio, residence, residenceBlock, residenceNumber, adminCondominio, city, state, phone, whatsapp,
  premium, datePayment, dateValidPremium, dateCreated, codeConfirm, accountConfirm, admin, order, active, sendEmail, onDelete, loadUsers, setLoading, redirect }) => {
  const [toggleItemUser, setToggleItemUser] = useState([]);
  const [stateUserAdmin, setStateUserAdmin] = useState(admin);
  const [stateAdminCondominio, setStateAdminCondominio] = useState(adminCondominio);
  const [stateOrder, setStateOrder] = useState(order);
  const [stateActive, setStateActive] = useState(active);
  const {user, setUser} = useContext(UserContext);
  const history = useHistory();

  async function handleSubmit(e){
    e.preventDefault();
    setLoading(true);
    if(item === "Morador"){
      try {
        const response = await Api.updateMorador(id, stateAdminCondominio, stateActive);
        console.log(response);
        if(response.ok === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setToggleItemUser([]);
          const reloadUsers = loadUsers;
          reloadUsers();
          setLoading(false);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else if(item === "Prestador"){
      try {
        const response = await Api.updatePrestador(id, stateActive);
        console.log(response);
        if(response.ok === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setToggleItemUser([]);
          const reloadUsers = loadUsers;
          reloadUsers();
          setLoading(false);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  
  return (
      <li className={toggleItemUser[0] === id ? "itemUserList itemUserListActive" : "itemUserList"}  style={active || stateActive ? {} : {opacity: 0.8}}>
        <div className="row headList" onClick={() => {toggleItemUser[0] !== id ? setToggleItemUser([id]) : setToggleItemUser([])}}>
          <div className="col-9">
            {item === "Prestador" ?
              <span>[{servico.name.toUpperCase()}] {name} - {city}</span>
            : 
              <span>{name} - {admin ? 'ADMINISTRADOR' : condominio.name}</span>
            }
          </div>
          <div className="col-3 text-right">
            <span className={`iconItem ${!active ? 'fas fa-exclamation-triangle fa-lg ' : ''}`} style={{color: 'rgb(255 189 3)'}}></span>
            <span className={`iconItem ${adminCondominio ? 'fas fa-user-tie fa-lg ' : ''}`} style={{color: "#6e2fbf"}}> </span>
            &nbsp;&nbsp;&nbsp;
            <span className={`iconItem ${toggleItemUser[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItemUser[0] === id ?
          <div className="row">
            <div className="col-12">
              <hr/>
              <form onSubmit={handleSubmit}>
                <div className="row v-center">
                  <div className="col-12">
                    <div className="row">
                      <div className="form-group col-12">
                        {user[0].hasOwnProperty("adminCondominio") && user[0].adminCondominio ?
                        <> {/* window.open(`mailto:${email}`) */}
                          <div className="switch-absolute" onClick={() => sendEmail(email)} style={{width: 'auto', marginRight: 125, marginTop: -4, cursor: 'pointer'}}>
                            <FaEnvelope size="2em" className="itemHomeIcon" style={{color: "#6e2fbf"}}/>
                          </div>
                          <div className="switch-absolute" onClick={() => history.push(`/creator/dashboard/notifications?user=${encodeURI(name)}&token=${token}`)} style={{width: 'auto', marginRight: 85, marginTop: -4, cursor: 'pointer'}}>
                            <FaRegPaperPlane size="2em" className="itemHomeIcon" style={{color: "#6e2fbf"}}/>
                          </div>
                        </>
                        : <></>}
                        <label className="switch-absolute itemDashboardListSwitch">
                          <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                          <div className="slider"></div>
                        </label>
                        <div className="col-12">
                          <div className="row v-center"> 
                            <label id="thumbnail" style={item === "Prestador" ? {backgroundImage: `url(${thumbnail})`, backgroundColor: "#000"} : {backgroundImage: `url(${thumbnail})`}}></label>
                            <div style={{marginLeft: 15}}>
                              <div><label style={{fontSize: 20}}>{name}</label></div>
                              {item === "Prestador" ? <div>{servico.name} - {city} ({state})</div>
                              : admin ? <div>Administrador</div> : <div>Morador - {city} ({state})</div>}
                            </div>
                          </div>
                        </div>
                      </div>
                      {!admin ?
                        <div className="form-group col-12">
                          <label style={{marginLeft: 8}}>Detalhes:</label>
                          {item === "Prestador" ?
                            <div className="row" style={{marginLeft:5}}>
                              <div className="form-group col-6">
                                <div>Nome: <label>{name}</label></div>
                                <div>Plano Atual: <label>{plan ? plan.name : ''}</label></div>
                                {/*<div>Data Aquisição Premium: <label>{StateUserDatePayment ? StateUserDatePayment.substr(0,10).split("-").reverse().join('/') : ''}</label></div>*/}
                                <div>Data Expiração Premium: <label>{dateValidPremium ? dateValidPremium.substr(0,10).split("-").reverse().join('/') : ''}</label></div>
                                <div>Data de Cadastro: <label>{dateCreated ? dateCreated.substr(0,10).split("-").reverse().join('/') : ''}</label></div>
                              </div>  
                              <div className="form-group col-6">
                                <div>Email: <label>{email}</label></div>
                                <div>Status Premium: <label>{premium && new Date(dateValidPremium) >= new Date() ? 'Ativo' : 'Expirado'}</label></div>
                                {/*<div>Status da Conta: <label>{stateActive ? 'Ativo' : 'Inativo'}</label></div>*/}
                                <div>Telefone: <label>{phone}</label></div>
                                <div>WhatsApp: <label>{whatsapp}</label></div>
                              </div>  
                            </div>
                          : //Morador
                          <>
                            <div className="row" style={{marginLeft:5}}>
                              <div className="form-group col-6">
                                <div>Condominio: <label>{condominio.name}</label></div>
                                <div>Endereço: <label>{residence && residence !== "" ?  residence + " nº "+ residenceNumber + " - Bloco " + residenceBlock : ' '}</label></div>
                              </div>  
                              <div className="form-group col-6">
                                <div>Email: <label>{email}</label></div>
                                <div>Telefone: <label>{phone}</label></div>
                              </div>  
                            </div>
                            <div className="form-group col-12">
                              <label className="labelInput">Síndico:</label>
                              <select className="form-control" value={stateAdminCondominio} onChange={event => setStateAdminCondominio(event.target.value)}>
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                              </select>
                            </div> 
                          </>}    
                          </div> 
                      : <></>}
                    </div>     
                  </div>     
                      
                  <div className="col-12 text-right">
                    <button type="button" className="btn btn-delete" onClick={onDelete}>Excluir</button>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <button type="submit" className="btn btn-default">Salvar</button>
                  </div>
                </div>
              </form>
                
            </div>
          </div>
          : '' }
      </li>
  );
};

export default ListItemUser;
