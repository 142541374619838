//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import Functions from '../../components/Functions'
import ListItemBusiness from '../../components/ListItemBusiness'
import './styles.css';
import '../../App.css';
import UserContext from '../../contexts/UserContext';

export default function DashboardBusiness({setLoading}) {
  const [businesses, setBusinesses] = useState([]);
  const [plans, setPlans] = useState([]);
  const {user, setUser} = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();

  // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadBusinesses();
  }, []);

  useEffect(() => {
    if(history.location.search.indexOf('add') !== -1){
      setTimeout(() => {
        addBusiness();
      }, 500);
    }
  }, [location]);

  async function loadBusinesses() {
    try {
      const response = await Api.get(`/get-business-user/${user[0]._id}`);
      console.log(response);
      setBusinesses(response);
      loadPlans();
    } catch (error) {
      console.log(error)
    }
  }

  async function loadPlans() {
    try {
      const response = await Api.get(`/get-plans-active/true`);
      console.log(response);
      setPlans(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function addBusiness(){
    const businessCopy = Array.from(businesses);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    businessCopy.unshift({
      _id: businessCopy.length +1,
      user: user[0]._id,
      item: "Business",
      name: "",
      description: "",
      //thumbnail: "",
      city: "",
      state: "",
      address: "",
      CNPJ: "",
      razaoSocial: "",
      keyPIX: "",
      plan: {},
      plans: plans,
      active: true
    });
    await setBusinesses(businessCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(businessCopy.length)
  }

  async function removeBusiness(id){
    setLoading(true);
    const businessCopy = Array.from(businesses);
    var index = businessCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.post('/delete-business', {'id': id});
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          businessCopy.splice(index, 1);
          setBusinesses(businessCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      businessCopy.splice(index, 1);
      setBusinesses(businessCopy);
    }
    setLoading(false);
  }

  return (
    <div id="businessList" className="bodyDashboard">
      <br />
        <div className="v-center">
          <div className="col-6">
            <span className="title">Meus Canais</span>
          </div>
          <div className="col-6 text-right">
            <button type="button" onClick={addBusiness} className="btn btn-default"> Adicionar Canal </button>
          </div>
        </div>
        <div className="col-12">
          <br />
          <ul className="dashboardList">
          {
            businesses.length > 0 ?
            businesses.map(item => (
              <ListItemBusiness
                key={item._id}
                item={"Business"}
                user={user[0]._id}
                id={item._id}
                name={item.name}
                description={item.description}
                thumbnail={item.thumbnail} 
                city={item.city} 
                state={item.state} 
                address={item?.address ? item.address : ""} 
                CNPJ={item?.CNPJ ? item.CNPJ : ""}
                razaoSocial={item?.razaoSocial ? item.razaoSocial : ""} 
                keyPIX={item?.keyPIX ? item.keyPIX : ""}
                plan={item?.plan ? item.plan : {}}
                plans={plans}
                active={item.active} 
                onDelete={() => removeBusiness(item._id)}
                loadItem={() => loadBusinesses()}
                setLoading={setLoading}
              />
            )) :
            <div className="col-12 text-center">
              <br /><br /><br />
              <span className="labelFilter">Você ainda não possui canais adicionados!</span>
            </div>
          }
          </ul>
      </div>
    </div>
  )
}