import React, { useEffect, useState, useMemo } from 'react';
import InputMask from "react-input-mask";
import { sendNotification } from './Functions'
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';

const ListItemBusiness = ({ id, user, item, name, description, thumbnail, CNPJ, state, city, address, razaoSocial, keyPIX, plan, plans, active, onDelete, loadItem, setEditBusiness, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateName, setStateName] = useState(name);
  const [stateDescription, setStateDescription] = useState(description);
  const [stateThumbnail, setStateThumbnail] = useState(thumbnail);
  const [stateKeyPix, setStateKeyPix] = useState(keyPIX);
  const [stateCity, setStateCity] = useState(city);
  const [stateAddress, setStateAddress] = useState(address);
  const [stateCNPJ, setStateCNPJ] = useState(CNPJ);
  const [stateRazaoSocial, setStateRazaoSocial] = useState(razaoSocial);
  const [stateActive, setStateActive] = useState(active);
  const [statePlan, setStatePlan] = useState(plan);
  const [transactionOpen, setTransactionOpen] = useState(false);

  // Executa assim q a página é aberta;
  useEffect(() => {
    loadTransactions(id);
  }, []);

  // useMemo: executa quando determinado estado for alterado, neste caso o estado 'stateThumbnail'
  const preview = useMemo(() => {
    if(thumbnail !== "" && thumbnail === stateThumbnail){
      return thumbnail;
    } else {
      return stateThumbnail ? URL.createObjectURL(stateThumbnail) : null;
    }
  }, [stateThumbnail]);
  
  function onChangeThumbnailFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      //let file_name = e.target.files[0].name;
      //let file_type = e.target.files[0].type;
      //console.log('executou o file change: ' +  file_size);
      //Se maior que um 1mb(1048576)
      if(parseInt(file_size) >= 5240000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 5MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }else{
        setStateThumbnail(e.target.files[0])
      }
    }
  };

  async function loadTransactions(idBusiness) {
    try {
      setLoading(true);
      const response = await Api.get(`/get-transactions/${idBusiness}`);
      if(response.length > 0) {
        const hasTransactionOpen = await response.some(item => item.status === "Transferência Solicitada");
        if(hasTransactionOpen){
          setTransactionOpen(true);
        }
      }
      setLoading(false);
      return response;
    } catch (error) {
      console.log(error)
      return [];
    }
  }

  async function handleSubmit(e){
    e.preventDefault();
    console.log(statePlan);
    if(stateName !== "" && stateKeyPix !== "" && statePlan?._id && statePlan._id !== ""){
      setLoading(true);
      var fileThumbnail = stateThumbnail;
      //Cria um estrutura Multiform Data;
      const data = new FormData();
      data.append('id', id);
      data.append('user', user);
      data.append('name', stateName);
      data.append('keyPIX', stateKeyPix);
      if(!transactionOpen) {
        data.append('plan', statePlan._id);
      }
      if(typeof fileThumbnail === "object" && thumbnail !== fileThumbnail.name){
        data.append('thumbnail', thumbnail);
        data.append('file', fileThumbnail);
      }
      if(stateDescription && stateDescription !== undefined && stateDescription !== "")
        data.append('description', stateDescription);
      if(stateAddress && stateAddress !== undefined && stateAddress !== "")
        data.append('address', stateAddress);
      if(stateCity && stateCity !== undefined && stateCity !== "")
        data.append('city', stateCity);
      if(stateCNPJ && stateCNPJ !== undefined && stateCNPJ !== "")
        data.append('CNPJ', stateCNPJ);
      if(stateRazaoSocial && stateRazaoSocial !== undefined && stateRazaoSocial !== "")
        data.append('razaoSocial', stateRazaoSocial);
      data.append('active', stateActive);
      try {
        var route = "";
        if(item === "Business"){
          route = "/store-business";
        } else if(item === "editBusiness"){
          route = "/update-business";
        }
        const response = await Api.api.post(route, data);
        console.log(response);
        if(response.data.hasOwnProperty('_id')){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setToggleItem([]);
          const reloadItem = loadItem;
          reloadItem();
          setLoading(false);
          if(item === "editBusiness" || !id.toString().match(/^[0-9a-fA-F]{24}$/)){
            setEditBusiness(false);
          }
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
  
  return (
      <li className={item === "Business" ? toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList": "dashboardEditBusiness"} style={active || stateActive ? {} : {opacity: 0.4}}>
        {item === "Business" ?
          <div className="row v-center headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
            <div className="col-10">
              <span>{stateName}</span>
            </div>
            <div className="col-2 text-right">
              <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
            </div>
          </div>
        : <></>}
        {item === "editBusiness" || toggleItem[0] === id ?
          <>
            <hr/>
            <form onSubmit={handleSubmit}>
              <div className="row v-center">
                <div className="col-12">
                  <div className="row v-center">
                    <div className="form-group col-md-4 col-12">
                      {/*item === "Business" ?
                        <label className="switch itemDashboardListSwitch">
                          <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                          <div className="slider"></div>
                        </label>
                      : <></>*/}
                      <label className="labelImage">Imagem do Canal:</label>
                      <label id="thumbnailBusiness" style={{ backgroundImage: `url(${preview})` }} className={stateThumbnail ? 'has-thumbnail' : ''}>
                        <input type="file" onChange={onChangeThumbnailFile} />
                        <img src={camera} alt="Select img" />
                      </label>
                    </div>
                    <div className="form-group col-md-8 col-12">
                      <label className="labelInput">Nome do Canal: *</label>
                      <input
                        //disabled={item === "Business" ? "" : "disabled"}
                        required 
                        type="text" 
                        className="form-control" 
                        placeholder="Informe o nome do canal ou seu nome de streaming"
                        value={stateName}
                        onChange={event => setStateName(event.target.value)}
                      />
                      <div className="form-group"></div>
                      <label className="labelInput">Chave PIX do Canal: *</label>
                      <input
                        required 
                        type="text" 
                        className="form-control" 
                        placeholder="Informe a chave PIX do seu canal"
                        value={stateKeyPix}
                        onChange={event => setStateKeyPix(event.target.value)}
                      />
                      <div className="form-group"></div>
                      <label className="labelInput">Plano de Recebimento: *</label>
                      <select className="form-control" value={JSON.stringify(statePlan)} style={{padding:'25px 15px 15px 10px'}} onChange={event => setStatePlan(JSON.parse(event.target.value))} disabled={transactionOpen ? "disabled" : ""}>
                        {/*<option value={JSON.stringify(statePlan)}>{statePlan.name}</option>*/}
                        {plans.map((item, index) => (
                          <option key={index} value={JSON.stringify(item)}>{item.name} - {item.percentage}%</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="form-group col-12">
                          <label className="labelInput">Descrição: </label>
                          <textarea className="form-control" value={stateDescription} rows="3" onChange={event => setStateDescription(event.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>     
                </div>     
                {item === "editBusiness" ?  
                  <div className="col-12 text-center">
                    <button type="button" className="btn btn-delete" onClick={() => setEditBusiness(false)}> Fechar </button>
                      <span>&nbsp;&nbsp;&nbsp;</span>
                    <button type="submit" className="btn btn-default"> Salvar Alterações</button>
                    <br />
                    <br />
                  </div>
                :  
                  <div className="col-12 text-right">
                    <button type="button" className="btn btn-delete" onClick={onDelete}> Excluir </button>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <button type="submit" className="btn btn-default"> Salvar </button>
                  </div>
                }
              </div>
            </form>
          </>
        : '' }
      </li>
  );
};

export default ListItemBusiness;
