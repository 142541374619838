//useEffect = Método chamado ao carragar a página;
import React, { useContext, useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import ListItemUser from '../../components/ListItemUser'
import './styles.css';
import '../../App.css';
import UserContext from '../../contexts/UserContext';

export default function DashboardUsers(props) {
  const [users, setUsers] = useState([]);
  const {user, setUser} = useContext(UserContext);

 // Executa assim q a página é aberta;
  useEffect(() => {
    props.setLoading(true);
    loadUsers();
  }, []);

  async function loadUsers() {
    try {
      var response;
      if(user[0].hasOwnProperty("admin") && user[0].admin) {
        response = await Api.getUsers();
        console.log(response);
      } else if(user[0].hasOwnProperty("adminCondominio") && user[0].adminCondominio) {
        response = await Api.getUsersCondominio(user[0].idCondominio._id);
      }
      setUsers(response);
      console.log(response);
      props.setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function addUser(){
    const userCopy = Array.from(users);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    userCopy.unshift({
      _id: userCopy.length +1,
      token: "",
      name: "",
      email: "",
      premium: "",
      datePayment: "",
      dateValidPremium: "",
      codeConfirm: "",
      accountConfirm: "",
      admin: "",
      active: "",
      //date: new Date().toISOString().substr(0,10),
    });
    await setUsers(userCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(userCopy.length)
  }

  async function removeUser(id){
    props.setLoading(true);
    const userCopy = Array.from(users);
    var index = userCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.deleteMorador(id);
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          userCopy.splice(index, 1);
          setUsers(userCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      userCopy.splice(index, 1);
      setUsers(userCopy);
    }
    props.setLoading(false);
  }

  //console.log(users);
  //const childRef = useRef();
  return (
    <div id="userList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-4">
          <span className="title">Moradores</span>
        </div>
        <div className="col-4 text-center">
        </div>
        <div className="col-4 text-right">
          {/*<button type="button" onClick={addUser} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>*/}
        </div>
      </div>
        <div className="col-12">
          <ul className="userList">
            {users.length > 0 ?
            users.map(item => (
              <ListItemUser
                key={item._id}
                item="Morador"
                id={item._id}
                token={item.token}
                name={item.name}
                thumbnail="https://storage.googleapis.com/appviaki-files/icon-profile-white.png"
                city={item.city} 
                state={item.state}
                email={item.email}
                phone={item.telefone} 
                condominio={item.idCondominio}
                residence={item.residence}
                residenceBlock={item.residenceBlock}
                residenceNumber={item.residenceNumber}
                //premium={item.premium}
                //datePayment={item.datePayment} 
                //dateValidPremium={item.dateValidPremium}
                //codeConfirm={item.codeConfirm} 
                //accountConfirm={item.accountConfirm} 
                adminCondominio={item.adminCondominio}
                admin={item.admin}
                active={item.active}
                onDelete={() => removeUser(item._id)}
                loadUsers={() => loadUsers()}
                setLoading={props.setLoading}
                sendEmail={props.sendEmail}
                //redirect={(route) => {props.history.push(route); window.location.reload(); }}
                //ref={childRef}
              />
            )) :
            <div className="col-12 text-center">
              <span className="labelFilter">Nenhum usuário encontrado!</span>
            </div>}
          </ul>
      </div>
    </div>
  )
 
}